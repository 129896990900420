import {
  DrawTempFeature,
  DrawTempSegmentProperties,
  GeoJsonFeature,
  GeoJsonFeatureCollection,
  GeoJsonGeometryType,
  GeoJsonSubtype,
  LayerFeature,
  SearchFeature,
} from "kaminow-shared";

export const isDrawFeature = (feature: GeoJsonFeature) => {
  return feature.properties.subtype === GeoJsonSubtype.Draw;
};

export const isDrawTempFeature = (feature: GeoJsonFeature) => {
  return feature.properties.subtype === GeoJsonSubtype.DrawTemp;
};

export const isPointFeature = (feature: GeoJsonFeature) => {
  return feature.properties.subtype === GeoJsonSubtype.Point;
};

export const isSearchFeature = (feature: GeoJsonFeature) => {
  return feature.properties.subtype === GeoJsonSubtype.Search;
};

export const isMapkitSearchFeature = (feature: GeoJsonFeature) => {
  return (
    isSearchFeature(feature) &&
    (feature as SearchFeature).properties.searchProvider === "mapkit"
  );
};

export const isImportedLineStringFeature = (feature: GeoJsonFeature) => {
  return (
    feature.properties.subtype === GeoJsonSubtype.Import &&
    feature.geometry.type === GeoJsonGeometryType.LineString
  );
};

export const isImportedLineMultiStringFeature = (feature: GeoJsonFeature) => {
  return (
    feature.properties.subtype === GeoJsonSubtype.Import &&
    feature.geometry.type === GeoJsonGeometryType.MultiLineString
  );
};

export const areRequestsPending = (
  geojson: GeoJsonFeatureCollection<LayerFeature>
) => {
  return (
    geojson?.features?.some((feature) => areFeatureRequestsPending(feature)) ||
    false
  );
};

const areFeatureRequestsPending = (feature: GeoJsonFeature) => {
  return (feature as DrawTempFeature).properties?.segments?.some(
    (segment) => (segment as DrawTempSegmentProperties).requestId
  );
};
