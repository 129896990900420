import { Box } from "@mui/material";

export const AppFooter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#2B71AD",
        width: "100%",
        height: "200px",
      }}
    ></Box>
  );
};
