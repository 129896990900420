export const hexToHSL = (hex: string) => {
  // Remove the hash sign if it exists
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values from hex
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find min and max values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0; // Hue
  let s = 0; // Saturation
  const l = (max + min) / 2; // Lightness

  if (delta !== 0) {
    // Calculate saturation
    s = delta / (1 - Math.abs(2 * l - 1));

    // Calculate hue
    if (max === r) {
      h = ((g - b) / delta + (g < b ? 6 : 0)) * 60;
    } else if (max === g) {
      h = ((b - r) / delta + 2) * 60;
    } else if (max === b) {
      h = ((r - g) / delta + 4) * 60;
    }
  }

  return { h, s, l };
};
