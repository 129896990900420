import { breakPoints } from "../../constants";

const defaultRatio = 3 / 2;

type OptimizedImageProps = {
  src: string;
  format?: "landscape" | "square" | "portrait";
  ratio?: number; // Original image ratio
  scale?: number; // Reduce image size if it does not take full screen
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const OptimizedImage = (props: OptimizedImageProps) => {
  const { src, format, ratio = defaultRatio, scale = 1, ...other } = props;

  return (
    <picture>
      {(!format || format === "landscape") &&
        breakPoints.map(({ key, value }) => {
          const mediaQuery =
            format === "landscape"
              ? `(max-width: ${value / scale}px)`
              : `(max-width: ${
                  value / scale
                }px) and (min-aspect-ratio: ${ratio})`;
          return (
            <source
              key={`${key}-landscape`}
              srcSet={`/image/${src}?size=${key}&format=landscape, /image/${src}?size=${key}&format=landscape&resolution=2 2x`}
              media={mediaQuery}
            />
          );
        })}
      {(!format || format === "portrait") &&
        breakPoints.map(({ key, value }) => {
          const mediaQuery =
            format === "portrait"
              ? `(max-height: ${value / scale}px)`
              : `(max-height: ${value / scale}px) and (max-aspect-ratio: ${
                  1 / ratio
                })`;
          return (
            <source
              key={`${key}-portrait`}
              srcSet={`/image/${src}?size=${key}&format=portrait, /image/${src}?size=${key}&format=portrait&resolution=2 2x`}
              media={mediaQuery}
            />
          );
        })}

      {(!format || format === "square") &&
        breakPoints.map(({ key, value }) => {
          const mediaQuery = `(max-width: ${value / scale}px) 
          and (max-height: ${value / scale}px)`;
          return (
            <source
              key={`${key}-square`}
              srcSet={`/image/${src}?size=${key}&format=square, /image/${src}?size=${key}&format=square&resolution=2 2x`}
              media={mediaQuery}
            />
          );
        })}

      <img src={`/image/${src}?size=xxl`} {...other} />
    </picture>
  );
};
