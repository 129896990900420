/* eslint-disable @typescript-eslint/no-empty-object-type */
import { createTheme } from "@mui/material";

type CustomTheme = {
  color: string;
  fontSize: string;
  secondaryColor: string;
  secondaryFontSize: string;
  backgroundColor: string;
  cardBackgroundColor: string;
  waypointBackgroundColor: string;
  selectedBackgroundColor: string;
  hoverBackgroundColor: string;
  boxShadow: string;
  boxShadowLight: string;
  iconFontSize: number;
  stroke: string;
  padding: string;
  margin: string;
};

declare module "@mui/material/styles" {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Arial", sans-serif',
  },
  color: "black",
  fontSize: "16px",
  secondaryColor: "gray",
  secondaryFontSize: "14px",
  backgroundColor: "#f8f8f8",
  cardBackgroundColor: "#F1F1F1",
  waypointBackgroundColor: "#fafafa",
  selectedBackgroundColor: "#F2F2F2",
  hoverBackgroundColor: "#F2F2F2",
  boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
  boxShadowLight: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  iconFontSize: 22,
  stroke: "rgba(0,0,0,0.4)",
  padding: "10px",
  margin: "10px",
});
