import {
  combineStats,
  DrawProfile,
  GeoJsonFeature,
  GeoJsonFeatureCollection,
  GeoJsonProperties,
  GeoJsonSubtype,
  LayerFeature,
  UniqueKeys,
} from "kaminow-shared";
import isEqual from "lodash.isequal";
import { atomFamily, DefaultValue, selector, selectorFamily } from "recoil";
import { patchRealtimeGeojson } from "../api/geojsons.api";
import { SpecialOverlays } from "../types/map.types";
import { areRequestsPending } from "../utils/geojson-types.utils";
import { deleteGeoJsonFeature, insertInGeoJson } from "../utils/geojson.utils";
import {
  dataSyncModeState,
  geojsonsEditedState,
  syncPendingCountState,
} from "./data-sync.state";
import { editLayerState, layerColorState, layerState } from "./layers.state";

/**
 * GEOJSONS
 */

// Local state

export const geojsonState = atomFamily<
  GeoJsonFeatureCollection<LayerFeature> | undefined,
  { adventureId: string; layerId: string }
>({
  key: "geojson",
  default: undefined,
});

// Edit

export const editGeojsonState = selector<
  (
    adventureId: string,
    layerId: string,
    newData: GeoJsonFeatureCollection<LayerFeature>
  ) => Promise<void>
>({
  key: "editGeojson",
  get: ({ get, getCallback }) => {
    const editLayer = get(editLayerState);
    return getCallback(
      ({ snapshot, set }) =>
        async (
          adventureId: string,
          layerId: string,
          newData: GeoJsonFeatureCollection<LayerFeature>
        ) => {
          // Update local state
          set(geojsonState({ adventureId, layerId }), newData);

          // Handle case where there are pending requests from ORS
          if (areRequestsPending(newData)) return;

          // Manage sync with cloud
          const dataSyncMode = await snapshot.getPromise(dataSyncModeState);
          if (dataSyncMode.REALTIME_WRITE) {
            patchRealtimeGeojson(adventureId, layerId, newData);
          } else {
            set(geojsonsEditedState(adventureId), (geojsonsEdited) =>
              Array.from(new Set([...geojsonsEdited, layerId]))
            );
            set(syncPendingCountState(adventureId), (count) => count + 1);
          }

          // Propagate geojson -> layer
          const layer = await snapshot.getPromise(
            layerState({ adventureId, layerId })
          );
          if (!layer) return;

          let shouldPropagate = false;

          const oldStats = layer.stats;
          const stats = combineStats(
            newData.features.map((feature) => feature.properties.stats)
          );

          const oldBbox = layer.bbox;
          const bbox = newData.bbox;

          shouldPropagate =
            !isEqual(stats, oldStats) || !isEqual(bbox, oldBbox);

          if (shouldPropagate)
            editLayer(adventureId, layerId, {
              bbox,
              stats,
            });
        }
    );
  },
});

/**
 * GEOJSON FEATURES
 */

// Derived state

export const featureIdsState = selectorFamily<
  string[] | undefined,
  { adventureId: string; layerId: string }
>({
  key: "featureIds",
  get:
    ({ adventureId, layerId }) =>
    ({ get }) => {
      const geojson = get(geojsonState({ adventureId, layerId }));
      return geojson?.features?.map((feature) => feature.id);
    },
});

export const featureState = selectorFamily<
  LayerFeature | undefined,
  { adventureId: string; layerId: string; featureId: string }
>({
  key: "feature",
  get:
    ({ adventureId, layerId, featureId }) =>
    ({ get }) => {
      const geojson = get(geojsonState({ adventureId, layerId }));
      return geojson?.features?.find((f) => f.id === featureId);
    },
});

// Edit

export const editFeatureState = selector<
  (
    adventureId: string,
    layerId: string,
    featureId: string,
    newData: (LayerFeature & { position?: number }) | null
  ) => Promise<void>
>({
  key: "editFeature",
  get: ({ get, getCallback }) => {
    const editGeojson = get(editGeojsonState);
    return getCallback(
      ({ snapshot }) =>
        async (
          adventureId: string,
          layerId: string,
          featureId: string,
          newData: (LayerFeature & { position?: number }) | null
        ) => {
          // Geojson
          const geojson = await snapshot.getPromise(
            geojsonState({ adventureId, layerId })
          );
          if (!geojson) return;

          let newGeojson: GeoJsonFeatureCollection<LayerFeature>;
          if (!newData) {
            newGeojson = deleteGeoJsonFeature(geojson, featureId);
          } else {
            const { position } = newData;
            delete newData["position"];
            newGeojson = insertInGeoJson(geojson, newData, position);
          }
          editGeojson(adventureId, layerId, newGeojson);
        }
    );
  },
});

/**
 * GEOJSON FEATURE PROPERTIES
 */

// Derived state

export const featurePropertyState = selectorFamily<
  any | undefined,
  {
    adventureId: string;
    layerId: string;
    featureId: string;
    property: UniqueKeys<GeoJsonProperties>;
  }
>({
  key: "featureProperty",
  get:
    ({ adventureId, layerId, featureId, property }) =>
    ({ get }) => {
      const feature = get(featureState({ adventureId, layerId, featureId }));
      if (!feature) return undefined;
      const properties = feature.properties as any;
      return properties[property];
    },
});

export const featureColorState = selectorFamily<
  string | undefined,
  { adventureId: string; layerId: string; featureId: string }
>({
  key: "featureColor",
  get:
    ({ adventureId, layerId, featureId }) =>
    ({ get }) => {
      const featureColor = get(
        featurePropertyState({
          adventureId,
          layerId,
          featureId,
          property: "stroke",
        })
      );
      if (featureColor) return featureColor;
      const layerColor = get(layerColorState({ adventureId, layerId }));
      if (layerColor) return layerColor;
      return undefined;
    },
});

export const drawFeatureSegmentProfilesState = selectorFamily<
  DrawProfile[] | null,
  { adventureId: string; layerId: string; featureId: string }
>({
  key: "drawFeatureSegmentProfiles",
  get:
    ({ adventureId, layerId, featureId }) =>
    ({ get }) => {
      const feature = get(featureState({ adventureId, layerId, featureId }));
      if (feature?.properties?.subtype !== GeoJsonSubtype.Draw) return null;
      const segments = feature.properties.segments;
      return segments.map((segment) => segment.profile);
    },
});

// Edit

type PropertyValue = any;
export const editFeaturePropertyState = selector<
  (
    adventureId: string,
    layerId: string,
    featureId: string,
    property: UniqueKeys<GeoJsonProperties>,
    value: PropertyValue
  ) => Promise<void>
>({
  key: "editFeatureProperty",
  get: ({ get, getCallback }) => {
    const editFeature = get(editFeatureState);
    return getCallback(
      ({ snapshot }) =>
        async (
          adventureId: string,
          layerId: string,
          featureId: string,
          property: UniqueKeys<GeoJsonProperties>,
          value: PropertyValue
        ) => {
          const feature = await snapshot.getPromise(
            featureState({ adventureId, layerId, featureId })
          );
          if (!feature) return undefined;

          const newProperties: any = {
            ...feature.properties,
          };
          if (
            value instanceof DefaultValue ||
            value === "" ||
            value === undefined ||
            value === null
          ) {
            delete newProperties[property];
          } else {
            newProperties[property] = value;
          }
          editFeature(adventureId, layerId, featureId, {
            ...feature,
            properties: newProperties,
          });
        }
    );
  },
});

/**
 * SPECIAL GEOJSONS
 */

export const specialGeojsonState = atomFamily<
  GeoJsonFeatureCollection | undefined,
  SpecialOverlays
>({
  key: "specialGeojson",
  default: undefined,
});

export const specialFeatureState = selectorFamily<
  GeoJsonFeature | undefined,
  { specialLayerId: SpecialOverlays; featureId: string }
>({
  key: "specialFeature",
  get:
    ({ specialLayerId, featureId }) =>
    ({ get }) => {
      const geojson = get(specialGeojsonState(specialLayerId));
      return geojson?.features.find((f) => f.id === featureId);
    },
});
