import {
  AccountCircle,
  FeedbackOutlined,
  LoginOutlined,
  Logout,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { getAuth, signOut } from "firebase/auth";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useFeedback } from "../../hooks/feedback/useFeedback";
import { PATHS } from "../../paths";
import { userState } from "../../state/auth.state";
import { debugState } from "../../state/debug.state";
import { DebugOptions } from "../debug/DebugOptions";
import { LanguageSwitcher } from "../language/LanguageSwitcher";

export const UserNav = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useRecoilValue(userState);

  const debug = useRecoilValue(debugState);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { sendFeedbackWithUI } = useFeedback();

  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    []
  );

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleFeeback = async () => {
    handleCloseUserMenu();
    sendFeedbackWithUI();
  };

  const handleSignIn = useCallback(async () => {
    navigate(PATHS["signin"].path);
  }, [navigate]);

  const handleSignOut = useCallback(async () => {
    handleCloseUserMenu();
    await signOut(getAuth());
    // await navigate(PATHS["home"].path);
    // window.location.reload();
    // Navigate + reload in order to clean up the recoil state and unsuscribe from the auth-ed firebase listeners
    window.location.href = PATHS["home"].path;
  }, [navigate]);

  return (
    <>
      <IconButton
        sx={{
          color: "primary.contrastText",
          bgcolor: "primary.main",
          "&:hover": { bgcolor: "primary.dark" },
        }}
        onClick={handleOpenUserMenu}
      >
        <AccountCircle />
      </IconButton>

      <Menu
        sx={{ mt: "45px" }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {user
          ? [
              <Typography key="profile" sx={{ padding: "6px 16px" }}>
                {user.email}
              </Typography>,
              <Divider key="divider1" />,
              <LanguageSwitcher key="languages" />,
              debug && <Divider key="divider2" />,
              debug && <DebugOptions key="debug" />,
              debug && <Divider key="divider3" />,
              <MenuItem key="feedback" onClick={handleFeeback}>
                <ListItemIcon>
                  <FeedbackOutlined fontSize="small" />
                </ListItemIcon>
                {`${t("nav.feedback")}`}
              </MenuItem>,
              <MenuItem key="sign-out" onClick={handleSignOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {`${t("nav.sign-out")}`}
              </MenuItem>,
            ]
          : [
              <LanguageSwitcher key="languages" />,
              <MenuItem key="sign-in" onClick={handleSignIn}>
                <ListItemIcon>
                  <LoginOutlined fontSize="small" />
                </ListItemIcon>
                {`${t("nav.sign-in")}`}
              </MenuItem>,
            ]}
      </Menu>
    </>
  );
};
