import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Box, Rating, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { useRecoilState } from "recoil";
import {
  dataSyncModeState,
  SYNC_MODES_OPTIONS,
} from "../../state/data-sync.state";
import { SYNC_DEBOUNCE_TIME } from "../adventure/AdventureStateManager";

export const DebugOptions = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [dataSyncMode, setDataSyncMode] = useRecoilState(dataSyncModeState);
  const [hoveredOption, setHoveredOption] = useState<number | null>(null);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        padding: "10px 16px",
        alignItems: "center",
      }}
    >
      <Typography>{t("debug.data-sync")}</Typography>
      <Tooltip
        key={`${hoveredOption}`}
        title={
          <Fragment>
            <Typography color="inherit">
              {t(`debug.data-sync-option-title-${hoveredOption}`)}
            </Typography>
            <Typography color="inherit" fontSize={14}>
              <Trans
                i18nKey={`debug.data-sync-option-description-${hoveredOption}`}
                components={{ b: <b />, br: <br /> }}
                values={{ time: SYNC_DEBOUNCE_TIME / 1000 }}
              />
            </Typography>
          </Fragment>
        }
      >
        <Rating
          value={dataSyncMode.id}
          onChange={(_, number) => {
            if (!number || number === 0) return;
            setDataSyncMode(SYNC_MODES_OPTIONS[number - 1]);
          }}
          onChangeActive={(_, number) => {
            setHoveredOption(number);
          }}
          precision={1}
          icon={
            <CircleIcon
              fontSize="inherit"
              sx={{ color: theme.palette.primary.main, opacity: 0.7 }}
            />
          }
          emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          max={SYNC_MODES_OPTIONS.length}
        />
      </Tooltip>
    </Box>
  );
};
