import { Box } from "@mui/material";
import { OverviewCard } from "./OverviewCard";

export const AppOverview = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "0px 10px", md: "60px 50px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: "20px", md: "60px" },
      }}
    >
      <OverviewCard index={1} imagePosition="left" />
      <OverviewCard index={2} imagePosition="right" />
      <OverviewCard index={3} imagePosition="left" />
    </Box>
  );
};
