import axios from "axios";
import { getServerBaseApiUrl, getUserToken } from "../utils/api.utils";

import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  ActionPackedAdventure,
  ActionPackedAdventureData,
  getAdventurePath,
  getAdventuresPath,
  getUserPath,
} from "kaminow-shared";
import { formatFirestoreData } from "../utils/firebase.utils";
import { createLayer } from "./layers.api";

/**
 * ADVENTURES
 */

export const fetchUserAdventures = async (userId: string) => {
  const db = getFirestore();
  const path = getAdventuresPath();
  const q = query(collection(db, path), where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  const adventures: ActionPackedAdventure[] = [];
  querySnapshot.forEach((doc: any) => {
    adventures.push({ ...doc.data(), id: doc.id });
  });
  return adventures;
};

export const fetchSharedAdventures = async (userId: string) => {
  const db = getFirestore();
  const userPath = getUserPath(userId);
  const userRef = doc(db, userPath);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    const userData = userSnap.data();
    const sharedAdventures = userData.sharedAdventures || [];

    if (!sharedAdventures.length) return [];

    const adventuresPath = getAdventuresPath();
    const q = query(
      collection(db, adventuresPath),
      where("__name__", "in", sharedAdventures)
    );
    const querySnapshot = await getDocs(q);
    const adventures: ActionPackedAdventure[] = [];
    querySnapshot.forEach((doc: any) => {
      adventures.push({ ...doc.data(), id: doc.id });
    });
    return adventures;
  }
  return [];
};

/**
 * ADVENTURE
 */

// Firebase direct

export const createAdventure = async (userId: string): Promise<string> => {
  const db = getFirestore();
  const path = getAdventuresPath();
  const newAdventure: Partial<ActionPackedAdventureData> = {
    userId,
    createdAt: serverTimestamp(),
    lastUpdate: serverTimestamp(),
  };
  const adventureRef = await addDoc(collection(db, path), newAdventure);
  const adventureId = adventureRef.id;
  await createLayer(adventureId, { position: 0 });
  return adventureRef.id;
};

export const getAdventure = async (
  adventureId: string
): Promise<ActionPackedAdventure | undefined> => {
  const db = getFirestore();
  const path = getAdventurePath(adventureId);
  const ref = doc(db, path) as DocumentReference<ActionPackedAdventure>;
  return (await getDoc(ref)).data();
};

export const listenToAdventure = (
  adventureId: string,
  setter: (adventure: ActionPackedAdventure | undefined) => void,
  onError?: (error: any) => void
) => {
  const db = getFirestore();
  const path = getAdventurePath(adventureId);
  const ref = doc(db, path) as DocumentReference<ActionPackedAdventure>;

  return onSnapshot(
    ref,
    (doc) => {
      if (!doc.exists()) {
        setter(undefined);
      } else {
        setter({ ...doc.data(), id: doc.id });
      }
    },
    (error) => {
      if (onError) {
        onError(error);
      }
    }
  );
};

export const patchAdventure = async (
  adventureId: string,
  newData: Partial<ActionPackedAdventureData>
): Promise<void> => {
  const db = getFirestore();
  const path = getAdventurePath(adventureId);
  const formattedData = formatFirestoreData({
    ...newData,
    lastUpdate: serverTimestamp(),
  });
  await updateDoc(doc(db, path), formattedData);
};

// Server

export const deleteAdventure = async (adventureId: string) => {
  const url = `${getServerBaseApiUrl()}/adventures/${adventureId}`;
  const token = await getUserToken();

  return await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
