import { Box, Grid2, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { OptimizedImage } from "../image/OptimizedImage";

export const AppMission = () => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h2">
        <Trans i18nKey={`home.mission-header`} />
      </Typography>
      <Box
        sx={{
          width: "40px",
          height: "5px",
          backgroundColor: "primary.main",
          margin: "20px 0px",
          marginBottom: "40px",
        }}
      />
      <Grid2
        container
        sx={{ width: "100%", maxWidth: "1200px" }}
        alignItems="center"
      >
        <Grid2
          size={{ xs: 12, md: 6 }}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography sx={{ padding: "0px 60px" }}>
            <Trans i18nKey={`home.mission-description`} />
          </Typography>
        </Grid2>
        <Grid2
          size={{ xs: 12, md: 6 }}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <OptimizedImage
            src={`team.jpeg`}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              paddingBottom: "0px",
              borderRadius: "5px",
              objectFit: "contain",
            }}
            scale={0.5}
            format={"landscape"}
            alt=""
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
