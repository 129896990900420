import { Box, Grid2, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { OptimizedImage } from "../image/OptimizedImage";

type OverviewCardProps = {
  index: number;
  imagePosition: "left" | "right";
};
export const OverviewCard = (props: OverviewCardProps) => {
  const { index, imagePosition } = props;

  return (
    <Grid2
      container
      spacing={{ xs: 0, md: 8 }}
      sx={{ width: "100%", maxWidth: "1200px" }}
      direction={
        imagePosition === "left"
          ? { xs: "column-reverse", md: "row" }
          : { xs: "column-reverse", md: "row-reverse" }
      }
      alignItems="center"
    >
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            margin: { xs: "10px", md: "0px" },
            maxWidth: { sm: "580px", md: "2000px" },
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "start" },
            justifyContent: "center",
            paddingLeft: { xs: "0px", md: index === 2 ? "40px" : "0px" },
          }}
        >
          <OptimizedImage
            src={`home-${index}.png`}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              paddingBottom: "0px",
              border: `0px solid rgba(0,0,0,0.8)`,
              borderRadius: "5px",
              objectFit: "contain",
            }}
            scale={0.5}
            format={index === 2 ? "portrait" : "landscape"}
            alt=""
          />
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "start" },
          maxWidth: "420px",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          <Trans i18nKey={`home.content-header-${index}`} />
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Trans i18nKey={`home.content-subheader-${index}`} />
        </Typography>
        <Box
          sx={{
            width: "40px",
            height: "5px",
            backgroundColor: "primary.main",
            margin: "20px 0px",
          }}
        />

        <Typography
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Trans
            i18nKey={`home.content-description-${index}`}
            components={{
              b: <b />,
              br: <span style={{ display: "block", marginBottom: "20px" }} />,
            }}
          />
        </Typography>
      </Grid2>
    </Grid2>
  );
};
