import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const API_URL = import.meta.env.VITE_API_URL;

export const getServerBaseUrl = () => {
  return API_URL || "";
};

export const getServerBaseApiUrl = () => {
  return getServerBaseUrl() + "/api";
};

export const getUserToken = async () => {
  const firebase = getApp();
  const auth = getAuth(firebase);
  const user = auth.currentUser;
  return user && (await user.getIdToken());
};
