import {
  ActionPackedAdventure,
  ActionPackedAdventureData,
  combineStats,
  DrawProfile,
  GeoJsonFeatureCollection,
  GeoJsonType,
  GpxExportFeature,
  GpxExportProperties,
  Stats,
} from "kaminow-shared";
import { atomFamily, selector, selectorFamily } from "recoil";
import { patchAdventure } from "../api/adventures.api";
import { hexToHSL } from "../utils/colors.utils";
import {
  adventureEditedFieldsState,
  dataSyncModeState,
  syncPendingCountState,
} from "./data-sync.state";
import {
  featureColorState,
  featureIdsState,
  geojsonState,
} from "./geojsons.state";
import {
  adventureLayerIdsState,
  adventureLayersState,
  layerStatsState,
} from "./layers.state";

/**
 * ADVENTURES
 */

export const userAdventuresState = atomFamily<
  ActionPackedAdventure[] | undefined,
  string
>({
  key: "userAdventures",
  default: undefined,
});

export const sharedAdventuresState = atomFamily<
  ActionPackedAdventure[] | undefined,
  string
>({
  key: "sharedAdventures",
  default: undefined,
});

/**
 * ADVENTURE
 */

// Local state

export const adventureState = atomFamily<
  ActionPackedAdventure | undefined,
  string
>({
  key: "adventure",
  default: undefined,
});

// Edit

export const editAdventureState = selector<
  (
    adventureId: string,
    newData: Partial<ActionPackedAdventureData>
  ) => Promise<void>
>({
  key: "editAdventure",
  get: ({ getCallback }) => {
    return getCallback(
      ({ snapshot, set }) =>
        async (
          adventureId: string,
          newData: Partial<ActionPackedAdventureData>
        ) => {
          // Fields being updated
          const updatedFields = Object.keys(
            newData
          ) as (keyof ActionPackedAdventureData)[];

          // Update local state
          set(adventureState(adventureId), (adventure) =>
            adventure ? { ...adventure, ...newData } : adventure
          );

          // Manage sync with cloud
          const dataSyncMode = await snapshot.getPromise(dataSyncModeState);
          const shouldSyncNow =
            dataSyncMode.REALTIME_WRITE &&
            updatedFields.some(
              (field) => !dataSyncMode.ADVENTURE_DELAYED_FIELDS.includes(field)
            );
          if (shouldSyncNow) {
            await patchAdventure(adventureId, newData);
          } else {
            set(adventureEditedFieldsState(adventureId), (fields) =>
              Array.from(new Set([...fields, ...updatedFields]))
            );
            set(syncPendingCountState(adventureId), (count) => count + 1);
          }
        }
    );
  },
});

// Derived state

export const adventureDescriptionState = selectorFamily<
  any | undefined,
  string
>({
  key: "adventureDescription",
  get:
    (adventureId) =>
    ({ get }) => {
      const adventure = get(adventureState(adventureId));
      return adventure?.description;
    },
});

export const adventureStatsState = selectorFamily<Stats | undefined, string>({
  key: "adventureStats",
  get:
    (adventureId) =>
    ({ get }) => {
      const dataSyncMode = get(dataSyncModeState);

      // Handle case where stats are delayed in realtime and some user is connected and needs updated stats of tracks being updated by some other user
      // TODO: Add more conditions to avoid computation for the user editing the track + Abstract logic to share with layers
      if (
        dataSyncMode.REALTIME_READ &&
        dataSyncMode.LAYER_DELAYED_FIELDS.includes("stats")
      ) {
        const layerIds = get(adventureLayerIdsState(adventureId));
        if (!layerIds?.length) return undefined;
        const layerStats = layerIds.map((layerId) =>
          get(layerStatsState({ adventureId, layerId }))
        );
        return combineStats(layerStats);
      } else {
        const adventure = get(adventureState(adventureId));
        return adventure?.stats;
      }
    },
});

const DEFAULT_PROFILE = DrawProfile.Hiking;
export const adventureLatestProfileState = selectorFamily<DrawProfile, string>({
  key: "adventureLatestProfileState",
  get:
    (adventureId) =>
    ({ get }) => {
      const adventure = get(adventureState(adventureId));
      return adventure?.latestProfile || DEFAULT_PROFILE;
    },
});

export const adventureAsExportState = selectorFamily<
  GeoJsonFeatureCollection<GpxExportFeature>,
  string
>({
  key: "adventureAsExport",
  get:
    (adventureId) =>
    ({ get }) => {
      const layers = get(adventureLayersState({ adventureId }));
      const allFeatures = layers
        .map((layer) => {
          const geojson = get(geojsonState({ adventureId, layerId: layer.id }));
          if (!geojson) return null;
          return geojson.features.map((feature) => {
            const properties = feature.properties;

            const exportProperties: GpxExportProperties = {};
            if (properties.name) exportProperties["name"] = properties.name;
            if (properties.icon) exportProperties["sym"] = properties.icon;

            const desc: { [key: string]: string } = { layerId: layer.id };
            if (layer.name) desc["layer"] = layer.name;
            if (properties.stroke) desc["stroke"] = properties.stroke;
            if (Object.values(desc).length)
              exportProperties["desc"] = JSON.stringify(desc);

            const exportFeature: GpxExportFeature = {
              ...feature,
              properties: exportProperties,
            };
            return exportFeature;
          });
        })
        .flat()
        .filter((f) => !!f);
      return {
        type: GeoJsonType.FeatureCollection,
        features: allFeatures,
      };
    },
});

export const adventureColorsState = selectorFamily<string[], string>({
  key: "adventureColors",
  get:
    (adventureId) =>
    ({ get }) => {
      const colors = new Set<string>();

      const layerIds = get(adventureLayerIdsState(adventureId));
      layerIds?.forEach((layerId) => {
        const featureIds = get(featureIdsState({ adventureId, layerId }));
        featureIds?.forEach((featureId) => {
          const color = get(
            featureColorState({ adventureId, layerId, featureId })
          );
          if (color) {
            colors.add(color);
          }
        });
      });

      return [...colors].sort((a, b) => {
        const hueA = hexToHSL(a).h;
        const hueB = hexToHSL(b).h;
        return hueA - hueB;
      });
    },
});
