import { deleteField } from "firebase/firestore";
import {
  ActionPackedAdventure,
  ActionPackedAdventureData,
  ActionPackedLayer,
  ActionPackedLayerData,
} from "kaminow-shared";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { patchAdventure } from "../api/adventures.api";
import { uploadGeojsonFile } from "../api/geojsons.api";
import { createLayer, deleteLayer, patchLayer } from "../api/layers.api";
import { adventureState } from "./adventures.state";
import { geojsonState } from "./geojsons.state";
import { adventureLayerIdsState, layerState } from "./layers.state";

// Mode

type DataSyncMode = {
  id: number;
  REALTIME_READ: boolean;
  REALTIME_WRITE: boolean;
  WRITE_HEURISTICS: boolean;
  ADVENTURE_DELAYED_FIELDS: (keyof ActionPackedAdventure)[];
  LAYER_DELAYED_FIELDS: (keyof ActionPackedLayer)[];
};

const SAVE_MODE: DataSyncMode = {
  id: 1,
  REALTIME_READ: false,
  REALTIME_WRITE: false,
  WRITE_HEURISTICS: false,
  ADVENTURE_DELAYED_FIELDS: [],
  LAYER_DELAYED_FIELDS: [],
};

const SIMULATED_REALTIME_MODE: DataSyncMode = {
  id: 2,
  REALTIME_READ: false,
  REALTIME_WRITE: false,
  WRITE_HEURISTICS: true,
  ADVENTURE_DELAYED_FIELDS: [],
  LAYER_DELAYED_FIELDS: [],
};

const SOLO_REAL_TIME_MODE: DataSyncMode = {
  id: 3,
  REALTIME_READ: false,
  REALTIME_WRITE: true,
  WRITE_HEURISTICS: false,
  ADVENTURE_DELAYED_FIELDS: ["bbox", "stats"],
  LAYER_DELAYED_FIELDS: ["bbox", "stats"],
};

const REAL_TIME_MODE: DataSyncMode = {
  id: 4,
  REALTIME_READ: true,
  REALTIME_WRITE: true,
  WRITE_HEURISTICS: false,
  ADVENTURE_DELAYED_FIELDS: ["bbox", "stats"],
  LAYER_DELAYED_FIELDS: ["bbox", "stats"],
};

const FORCED_REAL_TIME_MODE: DataSyncMode = {
  id: 5,
  REALTIME_READ: true,
  REALTIME_WRITE: true,
  WRITE_HEURISTICS: false,
  ADVENTURE_DELAYED_FIELDS: [],
  LAYER_DELAYED_FIELDS: [],
};

export const SYNC_MODES_OPTIONS = [
  SAVE_MODE,
  SIMULATED_REALTIME_MODE,
  SOLO_REAL_TIME_MODE,
  REAL_TIME_MODE,
  FORCED_REAL_TIME_MODE,
];

export const dataSyncModeState = atom<DataSyncMode>({
  key: "dataSyncMode",
  default: REAL_TIME_MODE,
});

// Adventures

export const adventureEditedFieldsState = atomFamily<string[], string>({
  key: "adventureEditedFields",
  default: [],
});

// Layers

export const layersCreatedState = atomFamily<string[], string>({
  key: "layersCreated",
  default: [],
});

export const layerEditedFieldsState = atomFamily<
  (keyof ActionPackedLayerData)[],
  { adventureId: string; layerId: string }
>({
  key: "layerEditedFields",
  default: [],
});

export const layersDeletedState = atomFamily<string[], string>({
  key: "layersDeleted",
  default: [],
});

// Geojsons

export const geojsonsEditedState = atomFamily<string[], string>({
  key: "geojsonsEdited",
  default: [],
});

// Sync

export const syncPendingCountState = atomFamily<number, string>({
  key: "syncPendingCount",
  default: 0,
});

export const hasSyncPendingState = selectorFamily<boolean, string>({
  key: "hasSyncPending",
  get:
    (adventureId: string) =>
    ({ get }) => {
      const editsPending = get(syncPendingCountState(adventureId));
      return editsPending > 0;
    },
});

export const syncLocalChangesState = selector<
  (adventureId: string) => Promise<void>
>({
  key: "syncLocalChanges",
  get: ({ get, getCallback }) => {
    const syncAdventure = get(syncAdventureState);
    const syncLayers = get(syncLayersState);
    return getCallback(({ reset }) => async (adventureId: string) => {
      reset(syncPendingCountState(adventureId));
      await Promise.all([syncAdventure(adventureId), syncLayers(adventureId)]);
    });
  },
});

export const syncAdventureState = selector<
  (adventureId: string) => Promise<void>
>({
  key: "syncAdventure",
  get: ({ getCallback }) => {
    return getCallback(({ snapshot, reset }) => async (adventureId: string) => {
      const adventure = await snapshot.getPromise(adventureState(adventureId));
      const fields = (await snapshot.getPromise(
        adventureEditedFieldsState(adventureId)
      )) as (keyof ActionPackedAdventureData)[];
      if (!adventure || !fields.length) return;

      const partialAdventureToUpdate = Object.fromEntries(
        fields.map((key) => {
          const value = adventure[key];
          return [key, value !== undefined ? value : deleteField()];
        })
      );
      patchAdventure(adventureId, partialAdventureToUpdate);

      reset(adventureEditedFieldsState(adventureId));
    });
  },
});

export const syncLayersState = selector<(adventureId: string) => Promise<void>>(
  {
    key: "syncLayers",
    get: ({ getCallback }) => {
      return getCallback(
        ({ snapshot, reset }) =>
          async (adventureId: string) => {
            const layerIds = await snapshot.getPromise(
              adventureLayerIdsState(adventureId)
            );
            const layersCreated = await snapshot.getPromise(
              layersCreatedState(adventureId)
            );
            reset(layersCreatedState(adventureId));
            const layersDeleted = await snapshot.getPromise(
              layersDeletedState(adventureId)
            );
            reset(layersDeletedState(adventureId));
            const geojsonsEdited = await snapshot.getPromise(
              geojsonsEditedState(adventureId)
            );
            reset(geojsonsEditedState(adventureId));

            await Promise.all(
              layerIds.map(async (layerId) => {
                const layer = await snapshot.getPromise(
                  layerState({ adventureId, layerId })
                );

                if (layersCreated.includes(layerId)) {
                  await createLayer(adventureId, { ...layer, id: layerId });
                  const geojson = await snapshot.getPromise(
                    geojsonState({ adventureId, layerId })
                  );
                  if (geojson)
                    await uploadGeojsonFile(adventureId, layerId, geojson);
                } else {
                  const editedFields = await snapshot.getPromise(
                    layerEditedFieldsState({ adventureId, layerId })
                  );
                  reset(layerEditedFieldsState({ adventureId, layerId }));
                  if (layer && editedFields.length) {
                    const partialLayerToUpdate = Object.fromEntries(
                      editedFields.map((key) => {
                        const value = layer[key];
                        return [
                          key,
                          value !== undefined ? value : deleteField(),
                        ];
                      })
                    );
                    await patchLayer(
                      adventureId,
                      layerId,
                      partialLayerToUpdate
                    );
                  }
                  if (geojsonsEdited.includes(layerId)) {
                    const geojson = await snapshot.getPromise(
                      geojsonState({ adventureId, layerId })
                    );
                    if (geojson)
                      await uploadGeojsonFile(adventureId, layerId, geojson);
                  }
                }
              })
            );

            await Promise.all(
              layersDeleted.map(async (layerId) => {
                await deleteLayer(adventureId, layerId);
              })
            );
          }
      );
    },
  }
);
