import { Box } from "@mui/material";
import { AppFeatures } from "./AppFeatures";
import { AppFooter } from "./AppFooter";
import { AppMission } from "./AppMission";
import { AppOverview } from "./AppOverview";

export const HomeContent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: { xs: "20px", md: "60px" },
      }}
    >
      <AppOverview />
      <AppFeatures />
      <AppMission />
      <AppFooter />
    </Box>
  );
};
