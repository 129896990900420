import axios from "axios";
import { getApp } from "firebase/app";
import {
  getDatabase,
  onValue,
  ref,
  remove,
  set as setFirebase,
} from "firebase/database";
import {
  GeoJsonFeatureCollection,
  getFirebaseGeojsonPath,
  LayerFeature,
} from "kaminow-shared";
import { getServerBaseApiUrl, getUserToken } from "../utils/api.utils";

/**
 * KAMINOW SERVER
 */

export const getGeojsonFile = async (
  adventureId: string,
  layerId: string,
  minimized = false
): Promise<GeoJsonFeatureCollection<LayerFeature> | undefined> => {
  const url = `${getServerBaseApiUrl()}/adventures/${adventureId}/layers/${layerId}/geojson`;
  const token = await getUserToken();

  const geojsonUrl = (
    await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { minimized },
    })
  ).data;
  if (!geojsonUrl) return undefined;
  const data = await fetch(geojsonUrl);
  return await data.json();
};

export const uploadGeojsonFile = async (
  adventureId: string,
  layerId: string,
  geojson: GeoJsonFeatureCollection
) => {
  const url = `${getServerBaseApiUrl()}/adventures/${adventureId}/layers/${layerId}/geojson`;
  const token = await getUserToken();

  await axios.put(url, geojson, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      propagateChanges: false,
    },
  });
};

export const listenToRealtimeGeojson = (
  adventureId: string,
  layerId: string,
  setter: (geojson: GeoJsonFeatureCollection<LayerFeature> | undefined) => void
) => {
  const path = getFirebaseGeojsonPath(adventureId, layerId);
  const geoRef = ref(getDatabase(getApp()), path);

  return onValue(geoRef, (snapshot) => {
    const data: string = snapshot.val();
    if (!data) {
      setter(undefined);
      return;
    }
    const geojson: GeoJsonFeatureCollection<LayerFeature> = JSON.parse(data);
    setter(geojson);
  });
};

export const patchRealtimeGeojson = async (
  adventureId: string,
  layerId: string,
  geojson: GeoJsonFeatureCollection
) => {
  const path = getFirebaseGeojsonPath(adventureId, layerId);
  await setFirebase(ref(getDatabase(getApp()), path), JSON.stringify(geojson));
};

export const deleteRealtimeGeojson = async (
  adventureId: string,
  layerId: string
) => {
  const path = getFirebaseGeojsonPath(adventureId, layerId);
  await remove(ref(getDatabase(getApp()), path));
};
