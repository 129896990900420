import bbox from "@turf/bbox";
import {
  DrawTempFeature,
  DrawTempSegmentProperties,
  GeoJsonFeature,
  GeoJsonFeatureCollection,
  GeoJsonGeometryType,
  GeoJsonType,
  GeometryPosition,
  LayerFeature,
} from "kaminow-shared";
import { WaypointsAsFeatureCollection } from "../types/geojson.types";
import { DrawGeoJsonSubtype } from "../types/interactions.types";

export const insertInGeoJson = <F extends GeoJsonFeature>(
  geoJson: GeoJsonFeatureCollection<F>,
  newFeature: F,
  position?: number
): GeoJsonFeatureCollection<F> => {
  const existingFeatures = geoJson?.features || [];
  const featureId = newFeature.id;
  const featureExists = existingFeatures.some((f) => f.id === featureId);

  let newFeatures: F[];

  if (featureExists) {
    // Replace the existing feature with the new one
    newFeatures = existingFeatures.map((f) =>
      f.id === featureId ? newFeature : f
    );
  } else if (position !== undefined && !isNaN(position)) {
    // Insert at a specified position if it's a valid number
    newFeatures = [...existingFeatures];
    newFeatures.splice(position, 0, newFeature);
  } else {
    // Append the new feature if no valid position is provided
    newFeatures = [...existingFeatures, newFeature];
  }

  const featureCollection: GeoJsonFeatureCollection<F> = {
    type: GeoJsonType.FeatureCollection,
    features: newFeatures,
  };

  const featureBbox = bbox(featureCollection);

  // Only assign bbox if all numbers are finite
  if (featureBbox.every(isFinite)) {
    featureCollection.bbox = featureBbox;
  }

  return featureCollection;
};

export const deleteGeoJsonFeature = <F extends GeoJsonFeature>(
  geoJson: GeoJsonFeatureCollection<F>,
  featureId: string
): GeoJsonFeatureCollection<F> => {
  const existingFeatures = geoJson.features;
  const newFeatures = existingFeatures.filter((f) => f.id !== featureId);

  // Return original collection if no feature was deleted
  if (newFeatures.length === existingFeatures.length) {
    return geoJson;
  }

  const featureCollection: GeoJsonFeatureCollection<F> = {
    type: GeoJsonType.FeatureCollection,
    features: newFeatures,
  };

  // Only assign bbox if all numbers are finite
  const featureBbox = bbox(featureCollection);
  if (featureBbox.every(isFinite)) {
    featureCollection.bbox = featureBbox;
  }

  return featureCollection;
};

export const isAPoint = (
  feature: GeoJsonFeatureCollection | GeoJsonFeature
) => {
  const featureType = feature?.type;
  if (!(featureType === "Feature")) return false;
  const geometryType = feature?.geometry?.type;
  if (!(geometryType === "Point")) return false;
  return true;
};

export const waypointsToFeature = (
  waypoints: GeometryPosition[],
  names: string[],
  stroke: string
): WaypointsAsFeatureCollection => {
  return {
    type: GeoJsonType.FeatureCollection,
    features: waypoints.map((f, index) => ({
      id: `${index}`,
      type: GeoJsonType.Feature,
      geometry: {
        type: GeoJsonGeometryType.Point,
        coordinates: f,
      },
      properties: {
        subtype: DrawGeoJsonSubtype.Waypoint,
        waypointIndex: index,
        name: names?.[index],
        stroke,
        isFirst: index === 0,
        isLast: index === waypoints.length - 1,
      },
    })),
  };
};

export const getWaypoints = (feature: DrawTempFeature) => {
  const coordinates = feature.geometry.coordinates;
  const segments = feature.properties.segments;
  if (!coordinates.length) return [];
  if (
    coordinates.length === 1 &&
    coordinates[0].length === 1 &&
    !(segments[0] as DrawTempSegmentProperties).requestId
  )
    return coordinates[0];

  const waypoints = coordinates.map(
    (cc, index) =>
      cc[0] || (segments[index] as DrawTempSegmentProperties).waypoints[0]
  );

  const lastIndex = coordinates.length - 1;
  const lastSegment = coordinates[lastIndex];
  if (lastSegment.length < 2) {
    waypoints.push(
      (segments[lastIndex] as DrawTempSegmentProperties).waypoints[1]
    );
  } else {
    waypoints.push(lastSegment[lastSegment.length - 1]);
  }

  return waypoints;
};

export const toBasicPointFeature = (
  coordinates?: GeometryPosition
): GeoJSON.Feature<GeoJSON.Point> => {
  return {
    type: GeoJsonType.Feature,
    geometry: {
      type: GeoJsonGeometryType.Point,
      coordinates: coordinates || [],
    },
    properties: {},
  };
};

export const toBasicLineFeature = (
  coordinates?: GeometryPosition[]
): GeoJSON.Feature<GeoJSON.LineString> => {
  return {
    type: GeoJsonType.Feature,
    geometry: {
      type: GeoJsonGeometryType.LineString,
      coordinates: coordinates || [],
    },
    properties: {},
  };
};

// Enhance geojson with feature ids in properties since mapbox promoteId does not work as expected
export const addIdsToProperties = (
  geojson: GeoJsonFeatureCollection<LayerFeature>
): GeoJsonFeatureCollection<LayerFeature> => {
  return {
    ...geojson,
    features: geojson.features.map((feature) => {
      return {
        ...feature,
        properties: { ...feature.properties, id: feature.id },
      };
    }) as LayerFeature[],
  };
};
