import { CssBaseline, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import "mapbox-gl/dist/mapbox-gl.css";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { DebugProvider } from "./components/debug/DebugProvider";
import { HomePage } from "./components/HomePage";
import Loading from "./components/loading/Loading";
import { RootPage } from "./components/RootPage";
import { firebaseConfig, sentryConfig } from "./config";
import "./global.css";
import "./i18n/config";
import { PATHS } from "./paths";
import { isSignedInCookieState } from "./state/auth.state";
import { theme } from "./theme";

if (process.env.NODE_ENV !== "local") {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: process.env.NODE_ENV || "development",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: "system",
        autoInject: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const SignInPage = lazy(() => import("./components/auth/SignIn"));
const SignUpPage = lazy(() => import("./components/auth/SignUp"));
const PasswordResetPage = lazy(() => import("./components/auth/PasswordReset"));
const AuthPage = lazy(() => import("./components/auth/AuthPage"));
const Map = lazy(() => import("./components/map/base/Map"));
const ExplorePage = lazy(() => import("./components/ExplorePage"));
const AdventurePage = lazy(() => import("./components/AdventurePage"));
const RegionsPage = lazy(() => import("./components/regions/SupportedRegions"));

const EmptyPage = lazy(() => import("./components/EmptyPage"));
const DebugPage = lazy(() => import("./components/DebugPage"));
const ToastContainer = lazy(
  () => import("./components/toast/ToastContainerWrapper")
);

initializeApp(firebaseConfig);

type AppProps = {
  isSignedInCookie?: boolean;
};
function App(props: AppProps) {
  const { isSignedInCookie } = props;

  return (
    <RecoilRoot
      initializeState={({ set }) => {
        if (isSignedInCookie !== undefined) {
          set(isSignedInCookieState, isSignedInCookie);
        }
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={PATHS["root"].path} element={<RootPage />} />
            <Route path={PATHS["home"].path} element={<HomePage />} />
            <Route path={PATHS["signin"].path} element={<SignInPage />} />
            <Route path={PATHS["signup"].path} element={<SignUpPage />} />
            <Route
              path={PATHS["passwordreset"].path}
              element={<PasswordResetPage />}
            />

            <Route element={<AuthPage />}>
              <Route element={<Map />}>
                <Route
                  path={PATHS["user_adventures"].path}
                  element={<ExplorePage />}
                />
                <Route
                  path={PATHS["adventure"].path}
                  element={<AdventurePage />}
                />
                <Route path={PATHS["empty"].path} element={<EmptyPage />} />
              </Route>
            </Route>
            <Route path={PATHS["regions"].path} element={<RegionsPage />} />
            <Route path={PATHS["debug"].path} element={<DebugPage />} />
          </Routes>
          <DebugProvider />
          <ToastContainer />
        </Suspense>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
