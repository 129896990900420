import { serverTimestamp } from "firebase/firestore";
import {
  ActionPackedAdventure,
  ActionPackedLayer,
  ActionPackedLayerData,
  combineBboxs,
  combineStats,
  Stats,
} from "kaminow-shared";
import { atomFamily, DefaultValue, selector, selectorFamily } from "recoil";
import {
  createLayer,
  deleteLayer,
  generateLayerId,
  patchLayer,
} from "../api/layers.api";
import { emptyFeatureCollection } from "../constants";
import { editAdventureState } from "./adventures.state";
import {
  dataSyncModeState,
  layerEditedFieldsState,
  layersCreatedState,
  layersDeletedState,
  syncPendingCountState,
} from "./data-sync.state";
import { geojsonState } from "./geojsons.state";

/**
 * LAYERS
 */

// Local state

export const adventureLayersState = atomFamily<
  ActionPackedLayer[],
  { adventureId: string }
>({
  key: "adventureLayers",
  default: [],
});

// Derived state

export const adventureLayerIdsState = selectorFamily<string[], string>({
  key: "adventureLayerIds",
  get:
    (adventureId) =>
    ({ get }) => {
      const layers = get(adventureLayersState({ adventureId }));
      return layers.map((l) => l.id);
    },
});

export const lastLayerPositionState = selectorFamily<
  any,
  { adventureId: string }
>({
  key: "lastLayerPosition",
  get:
    ({ adventureId }) =>
    ({ get }) => {
      const layers = get(adventureLayersState({ adventureId }));
      if (!layers.length) return null;
      const lastLayer = layers[layers.length - 1];
      return lastLayer.position;
    },
});

/**
 * LAYER
 */

// Derived state

export const layerState = selectorFamily<
  ActionPackedLayer | undefined,
  { adventureId: string; layerId: string }
>({
  key: "layer",
  get:
    ({ adventureId, layerId }) =>
    ({ get }) => {
      const layers = get(adventureLayersState({ adventureId }));
      return layers.find((layer) => layer.id === layerId);
    },
  set:
    ({ adventureId, layerId }) =>
    ({ get, set }, newLayer) => {
      if (newLayer instanceof DefaultValue || newLayer === undefined) return;

      const updatedLayers = get(adventureLayersState({ adventureId })).filter(
        (layer) => layer.id !== layerId
      );
      updatedLayers.push(newLayer);
      updatedLayers.sort((a, b) => a.position - b.position);
      set(adventureLayersState({ adventureId }), updatedLayers);
    },
});

export const layerColorState = selectorFamily<
  string | null | undefined,
  { adventureId: string; layerId: string }
>({
  key: "layerColor",
  get:
    ({ adventureId, layerId }) =>
    ({ get }) => {
      const layer = get(layerState({ adventureId, layerId }));
      return layer?.stroke;
    },
});

export const layerStatsState = selectorFamily<
  Stats | null | undefined,
  { adventureId: string; layerId: string }
>({
  key: "layerStats",
  get:
    ({ adventureId, layerId }) =>
    ({ get }) => {
      const dataSyncMode = get(dataSyncModeState);

      // Handle case where stats are delayed in realtime and some user is connected and needs updated stats of tracks being updated by some other user
      // TODO: Add more conditions to avoid computation for the user editing the track + Abstract logic to share with adventure
      if (
        dataSyncMode.REALTIME_READ &&
        dataSyncMode.LAYER_DELAYED_FIELDS.includes("stats")
      ) {
        const geojson = get(geojsonState({ adventureId, layerId }));
        if (!geojson) return undefined;
        return combineStats(
          geojson.features.map((feature) => feature.properties.stats)
        );
      } else {
        const layer = get(layerState({ adventureId, layerId }));
        return layer?.stats;
      }
    },
});

// Edit

export const createLayerState = selector<
  (
    adventureId: string,
    newData?: Partial<ActionPackedLayerData>
  ) => Promise<string>
>({
  key: "createLayer",
  get: ({ getCallback }) => {
    return getCallback(
      ({ snapshot, set }) =>
        async (
          adventureId: string,
          newData?: Partial<ActionPackedLayerData>
        ) => {
          // Mode
          const dataSyncMode = await snapshot.getPromise(dataSyncModeState);

          // Determine position
          const lastPosition = await snapshot.getPromise(
            lastLayerPositionState({ adventureId })
          );
          const position =
            newData?.position ?? (lastPosition !== null ? lastPosition + 1 : 0);

          // Create new layer
          let layerId: string;
          const newLayerData = {
            ...(newData || {}),
            position,
            createdAt: serverTimestamp(),
            lastUpdate: serverTimestamp(),
          };
          if (dataSyncMode.REALTIME_WRITE) {
            layerId = await createLayer(adventureId, newLayerData);
          } else {
            layerId = generateLayerId(adventureId);
            set(layersCreatedState(adventureId), (ids) => [...ids, layerId]);
            set(syncPendingCountState(adventureId), (count) => count + 1);
          }
          const newLayer = { ...newLayerData, id: layerId };

          // Update local state
          set(layerState({ adventureId, layerId }), newLayer);
          set(geojsonState({ adventureId, layerId }), emptyFeatureCollection);

          return layerId;
        }
    );
  },
});

export const editLayerState = selector<
  (
    adventureId: string,
    layerId: string,
    newData: Partial<ActionPackedLayerData>
  ) => Promise<void>
>({
  key: "editLayer",
  get: ({ get, getCallback }) => {
    const editAdventure = get(editAdventureState);
    return getCallback(
      ({ snapshot, set }) =>
        async (
          adventureId: string,
          layerId: string,
          newData: Partial<ActionPackedLayerData>
        ) => {
          // Fields being updated
          const updatedFields = Object.keys(
            newData
          ) as (keyof ActionPackedLayerData)[];

          // Update local state
          set(layerState({ adventureId, layerId }), (layer) =>
            layer ? { ...layer, ...newData } : layer
          );

          // Manage sync with cloud
          const dataSyncMode = await snapshot.getPromise(dataSyncModeState);
          const shouldSyncNow =
            dataSyncMode.REALTIME_WRITE &&
            updatedFields.some(
              (field) => !dataSyncMode.LAYER_DELAYED_FIELDS.includes(field)
            );
          if (shouldSyncNow) {
            await patchLayer(adventureId, layerId, newData);
          } else {
            set(layerEditedFieldsState({ adventureId, layerId }), (fields) =>
              Array.from(new Set([...fields, ...updatedFields]))
            );
            set(syncPendingCountState(adventureId), (count) => count + 1);
          }

          // Propagate layers -> adventure
          const {
            bbox: newBbox,
            stats: newStats,
            ignoreStats: newIgnoreStats,
          } = newData;

          if (
            !(
              newBbox !== undefined ||
              newStats !== undefined ||
              newIgnoreStats !== undefined
            )
          )
            return;

          const layers = (
            await snapshot.getPromise(adventureLayersState({ adventureId }))
          ).map((layer) =>
            layer.id === layerId ? { ...layer, ...newData } : layer
          );

          const newAdventure: Partial<ActionPackedAdventure> = {};
          if (newBbox !== undefined) {
            newAdventure["bbox"] = combineBboxs(
              layers.map((layer) => layer.bbox).filter((bbox) => !!bbox)
            );
          }
          if (newStats !== undefined || newIgnoreStats !== undefined) {
            newAdventure["stats"] = combineStats(
              layers.map((layer) => {
                if (!layer.ignoreStats) return layer.stats;
              })
            );
          }

          editAdventure(adventureId, newAdventure);
        }
    );
  },
});

export const deleteLayerState = selector<
  (adventureId: string, layerId: string) => Promise<void>
>({
  key: "deleteLayer",
  get: ({ get, getCallback }) => {
    const editAdventure = get(editAdventureState);
    return getCallback(
      ({ snapshot, set }) =>
        async (adventureId: string, layerId: string) => {
          // Update local state
          const layers = (
            await snapshot.getPromise(adventureLayersState({ adventureId }))
          ).filter((layer) => layer.id !== layerId);
          set(adventureLayersState({ adventureId }), layers);

          // Manage sync with cloud
          const dataSyncMode = snapshot.getLoadable(dataSyncModeState).contents;
          if (dataSyncMode.REALTIME_WRITE) {
            await deleteLayer(adventureId, layerId);
          } else {
            set(layersDeletedState(adventureId), (ids) => [...ids, layerId]);
            set(syncPendingCountState(adventureId), (count) => count + 1);
          }

          // Propagate layers -> adventure
          const newAdventure: Partial<ActionPackedAdventure> = {};
          newAdventure["bbox"] = combineBboxs(
            layers.map((layer) => layer.bbox).filter((bbox) => !!bbox)
          );
          newAdventure["stats"] = combineStats(
            layers.map((layer) => {
              if (!layer.ignoreStats) return layer.stats;
            })
          );
          editAdventure(adventureId, newAdventure);
        }
    );
  },
});
