import { useCallback, useEffect, useState } from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { getAdventure, listenToAdventure } from "../../api/adventures.api";
import { getLayers, listenToLayers } from "../../api/layers.api";

import {
  ActionPackedAdventure,
  GeoJsonFeatureCollection,
  LayerFeature,
} from "kaminow-shared";
import {
  deleteRealtimeGeojson,
  getGeojsonFile,
  listenToRealtimeGeojson,
  uploadGeojsonFile,
} from "../../api/geojsons.api";
import { adventureState } from "../../state/adventures.state";
import {
  dataSyncModeState,
  hasSyncPendingState,
  syncLocalChangesState,
  syncPendingCountState,
} from "../../state/data-sync.state";
import { geojsonState } from "../../state/geojsons.state";
import { adventureLayersState } from "../../state/layers.state";

type AdventureStateManagerProps = {
  adventureId: string;
  setUnauthorized: () => void;
};

/**
 * A component responsible for reading adventure data from the cloud.
 *
 * It can either operate in realtime with listeners or do a single GET and rely on local state later on.
 */
export const AdventureStateManager = (props: AdventureStateManagerProps) => {
  const { adventureId, setUnauthorized } = props;

  const dataSyncMode = useRecoilValue(dataSyncModeState);
  const realtimeRead = dataSyncMode.REALTIME_READ;
  const realtimeWrite = dataSyncMode.REALTIME_WRITE;

  const hasSyncPending = useRecoilValue(hasSyncPendingState(adventureId));
  const syncLocalChanges = useRecoilValue(syncLocalChangesState);

  const [adventure, setAdventure] = useRecoilState(adventureState(adventureId));
  const resetAdventure = useResetRecoilState(adventureState(adventureId));

  const [adventureLayers, setAdventureLayers] = useRecoilState(
    adventureLayersState({ adventureId })
  );

  const adventureLoaded = !!adventure;

  const updateAdventure = useCallback(
    (adventure: ActionPackedAdventure | undefined) => {
      if (adventure) {
        setAdventure(adventure);
      } else {
        resetAdventure();
      }
    },
    []
  );

  // READ on Adventure
  useEffect(() => {
    if (realtimeRead) {
      const unsubscribeAdventure = listenToAdventure(
        adventureId,
        updateAdventure,
        setUnauthorized
      );
      return () => {
        unsubscribeAdventure();
      };
    } else {
      const fetchAdventure = async () => {
        const adventure = await getAdventure(adventureId);
        updateAdventure(adventure);
      };
      fetchAdventure();
    }
  }, [adventureId, realtimeRead]);

  // READ on Layers
  useEffect(() => {
    if (!adventureLoaded) return;

    if (realtimeRead) {
      const unsubscribeLayers = listenToLayers(adventureId, setAdventureLayers);
      return () => {
        unsubscribeLayers();
      };
    } else {
      const fetchLayers = async () => {
        const layers = await getLayers(adventureId);
        setAdventureLayers(layers);
      };
      fetchLayers();
    }
  }, [adventureId, adventureLoaded, realtimeRead]);

  // Sync changes when mode changes or adventure is exited
  useEffect(() => {
    return () => {
      syncLocalChanges(adventureId);
    };
  }, [adventureId, dataSyncMode]);

  // Try to handle sync if app is closed and changes are pending
  useEffect(() => {
    if (!hasSyncPending) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      syncLocalChanges(adventureId);
      event.preventDefault();
      event.returnValue = ""; // Required for some browsers to trigger the prompt
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [adventureId, hasSyncPending]);

  return (
    <>
      {dataSyncMode.WRITE_HEURISTICS && (
        <WriteHeuristicHandler
          adventureId={adventureId}
          syncLocalChanges={syncLocalChanges}
        />
      )}
      {adventureLayers.map((layer) => (
        <GeojsonStateManager
          key={layer.id}
          adventureId={adventureId}
          layerId={layer.id}
          realtimeRead={realtimeRead}
          realtimeWrite={realtimeWrite}
        />
      ))}
    </>
  );
};

type GeojsonStateManagerProps = {
  adventureId: string;
  layerId: string;
  realtimeRead: boolean;
  realtimeWrite: boolean;
};
const GeojsonStateManager = (props: GeojsonStateManagerProps) => {
  const { adventureId, layerId, realtimeRead, realtimeWrite } = props;

  const setGeojson = useSetRecoilState(geojsonState({ adventureId, layerId }));
  const [hasFetched, setHasFetched] = useState(false);
  const [hasRealtimeData, setHasRealtimeData] = useState(false);

  const getLatestGeojson = useRecoilCallback(
    ({ snapshot }) =>
      (adventureId: string, layerId: string) => {
        const loadable = snapshot.getLoadable(
          geojsonState({ adventureId, layerId })
        );
        if (loadable.state === "hasValue") {
          return loadable.contents;
        }
      }
  );

  // READ on Geojsons

  // Initial read
  useEffect(() => {
    if (hasFetched) return;
    const fetchGeojson = async () => {
      const geojson = await getGeojsonFile(adventureId, layerId);
      if (geojson) setGeojson(geojson);
      setHasFetched(true);
    };
    fetchGeojson();
  }, [adventureId, layerId, hasFetched]);

  // Realtime listener
  useEffect(() => {
    if (!realtimeRead || !hasFetched) return;

    const handleFirebaseUpdate = (
      geojson: GeoJsonFeatureCollection<LayerFeature> | undefined
    ) => {
      if (geojson) {
        setGeojson(geojson);
        setHasRealtimeData(true);
      } else {
        setHasRealtimeData(false);
      }
    };
    const unsubscribeGeojson = listenToRealtimeGeojson(
      adventureId,
      layerId,
      handleFirebaseUpdate
    );
    return () => {
      unsubscribeGeojson();
    };
  }, [adventureId, layerId, realtimeRead, hasFetched]);

  // Commit realtime changes to storage if mode changes (otherwise the server might override future edits when socket closes)
  useEffect(() => {
    if (!(realtimeWrite && hasRealtimeData)) return;

    return () => {
      const geojson = getLatestGeojson(adventureId, layerId);
      if (geojson) {
        uploadGeojsonFile(adventureId, layerId, geojson);
        deleteRealtimeGeojson(adventureId, layerId);
      }
      setHasRealtimeData(false);
    };
  }, [hasRealtimeData, realtimeWrite]);

  return null;
};

export const SYNC_DEBOUNCE_TIME = 10000; // 10 seconds debounce
type WriteHeuristicHandlerProps = {
  adventureId: string;
  syncLocalChanges: (adventureId: string) => Promise<void>;
};
const WriteHeuristicHandler = (props: WriteHeuristicHandlerProps) => {
  const { adventureId, syncLocalChanges } = props;

  const editsPending = useRecoilValue(syncPendingCountState(adventureId));

  useEffect(() => {
    if (editsPending > 0) {
      const timeout = setTimeout(() => {
        syncLocalChanges(adventureId);
      }, SYNC_DEBOUNCE_TIME);
      return () => clearTimeout(timeout);
    }
  }, [editsPending, adventureId]);

  return null;
};
