import axios from "axios";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { ActionPackedLayer } from "kaminow-shared";
import { getServerBaseApiUrl, getUserToken } from "../utils/api.utils";
import { formatFirestoreData } from "../utils/firebase.utils";

/**
 * LAYERS
 */

export const getLayers = async (adventureId: string) => {
  const db = getFirestore();

  const layersPath = `adventures/${adventureId}/layers`;
  const layersQuery = query(collection(db, layersPath), orderBy("position"));
  const querySnapshot = await getDocs(layersQuery);
  const layers: ActionPackedLayer[] = [];
  querySnapshot.forEach((doc: any) => {
    layers.push({ ...doc.data(), id: doc.id });
  });
  return layers;
};

export const listenToLayers = (
  adventureId: string,
  setter: (layers: ActionPackedLayer[]) => void
) => {
  const db = getFirestore();

  const layersPath = `adventures/${adventureId}/layers`;
  const layersQuery = query(collection(db, layersPath), orderBy("position"));
  const unsubscribe = onSnapshot(layersQuery, (querySnapshot) => {
    const layers: ActionPackedLayer[] = [];
    querySnapshot.forEach((doc: any) => {
      layers.push({ id: doc.id, ...doc.data() });
    });
    setter(layers);
  });
  return unsubscribe;
};

/**
 * LAYER
 */

// Firebase direct

export const createLayer = async (
  adventureId: string,
  layer: Partial<ActionPackedLayer>
) => {
  const db = getFirestore();
  const path = `adventures/${adventureId}/layers`;
  const newLayer: Partial<ActionPackedLayer> = {
    ...layer,
    createdAt: serverTimestamp(),
  };
  const layerId = layer.id;
  if (layerId) {
    await setDoc(doc(db, path, layerId), newLayer);
    return layerId;
  }
  return (await addDoc(collection(db, path), newLayer)).id;
};

export const generateLayerId = (adventureId: string) => {
  const db = getFirestore();
  const path = `adventures/${adventureId}/layers`;
  return doc(collection(db, path)).id;
};

export const patchLayer = async (
  adventureId: string,
  layerId: string,
  newData: Partial<ActionPackedLayer>
) => {
  const db = getFirestore();
  const path = `adventures/${adventureId}/layers/${layerId}`;
  const formattedData = formatFirestoreData({
    ...newData,
    lastUpdate: serverTimestamp(),
  });
  updateDoc(doc(db, path), formattedData);
};

// Server

export const createLayerServer = async (
  adventureId: string,
  newLayer?: Partial<ActionPackedLayer>
) => {
  const url = `${getServerBaseApiUrl()}/adventures/${adventureId}/layers`;
  const token = await getUserToken();

  return (
    await axios.post(url, newLayer, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
};

export const deleteLayer = async (adventureId: string, layerId: string) => {
  const url = `${getServerBaseApiUrl()}/adventures/${adventureId}/layers/${layerId}`;
  const token = await getUserToken();

  await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
