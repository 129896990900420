import {
  CloudSyncOutlined,
  InsightsOutlined,
  LayersOutlined,
  PlaceOutlined,
  RouteOutlined,
  WifiOffOutlined,
} from "@mui/icons-material";

import { Grid2, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Trans } from "react-i18next";

export const AppFeatures = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "50px",
        backgroundColor: "#2B71AD",
        padding: { xs: "100px 20px", md: "3.75rem 1.25rem" },
        clipPath: "polygon(0% 0%, 50% 5%, 100% 0%, 100% 95%, 50% 100%, 0% 95%)",
        "@media (min-width: 1024px)": {
          clipPath:
            "polygon(0% 0%, 28% 5%, 100% 0%, 100% 95%, 50% 100%, 0% 95%)",
        },
        "@media (min-width: 1400px)": {
          clipPath:
            "polygon(0% 0%, 33% 5%, 100% 0%, 100% 95%, 50% 100%, 0% 95%)",
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "white",
          textAlign: "center",
          marginTop: { xs: "40px", md: "0px" },
          fontSize: {
            xs: "2rem",
            md: "3rem",
          },
        }}
      >
        What sets Kaminow appart?
      </Typography>
      <Grid2 container spacing={4} justifyContent="center">
        {[
          PlaceOutlined,
          LayersOutlined,
          CloudSyncOutlined,
          RouteOutlined,
          InsightsOutlined,
          WifiOffOutlined,
        ].map((Icon, index) => (
          <Grid2 size={{ xs: 12, md: 4 }} key={index + 1}>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Icon sx={{ fontSize: "50px", color: "white" }} />
              <Typography
                variant="h6"
                sx={{ color: "#f1f1f1", marginTop: "1rem" }}
              >
                <Trans i18nKey={`home.feature-header-${index + 1}`} />
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#f1f1f1",
                  marginTop: "0.5rem",
                  maxWidth: "340px",
                }}
              >
                <Trans i18nKey={`home.feature-description-${index + 1}`} />
              </Typography>
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};
