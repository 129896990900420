import { deleteField } from "firebase/firestore";

// Replace undefined or null with deleteField()
export const formatFirestoreData = (data: { [key: string]: any }) => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value == null ? deleteField() : value,
    ])
  );
};
