export type LayerMetadata = {
  origin: string;
  adventureId: string;
  layerId: string;
  canSelect: boolean;
};

/**
 * DRAW
 */

export enum DrawGeoJsonSubtype {
  Waypoint = "Waypoint",
  NewWaypoint = "NewWaypoint",
}

export enum HoverType {
  Feature = "Feature",
  NewWaypoint = "NewWaypoint",
  Waypoint = "Waypoint",
  NewMidWaypoint = "NewMidWaypoint",
  Dragging = "Dragging",
}
