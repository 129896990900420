import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { debugState } from "../../state/debug.state";

export const DebugProvider = () => {
  const setDebug = useSetRecoilState(debugState);
  const location = useLocation();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Ensure we are on the client before running debug logic
    setIsClient(typeof window !== "undefined");
  }, []);

  useEffect(() => {
    if (!isClient) return; // Prevent execution on the server

    // Get the hostname safely
    const hostname = window.location.hostname;

    // Get URL search params safely
    const searchParams = new URLSearchParams(location.search);
    const debugParam = searchParams.get("debug") === "1";

    // Check conditions
    const isLocalhost = hostname === "localhost";
    const isDevPrefix = hostname.startsWith("dev.");

    // Set debug state
    setDebug(isLocalhost || isDevPrefix || debugParam);
  }, [isClient, location]);

  return null;
};
